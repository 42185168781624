import React from "react"

export default () => (
  <div className="icon-files">
    <svg
      height="50"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 454.573 454.573"
    >
      <g>
        <g>
          <g>
            <path
              d="M452.441,156.234l-65.829-46.498V41.817c-0.66-6.728-5.843-12.128-12.539-13.061H85.682
				c-6.695,0.934-11.879,6.333-12.539,13.061v67.396l-68.441,47.02c-2.711,1.968-4.428,5.021-4.702,8.359v248.163
				c0.89,6.811,6.25,12.172,13.061,13.061h433.633c5.747,0,7.837-6.792,7.837-13.061V164.593
				C454.531,161.458,455.053,158.323,452.441,156.234z M386.612,134.813l44.931,30.824l-44.931,33.959V134.813z M94.041,49.654
				h271.673v166.139l-135.837,102.4l-135.837-102.4V49.654z M73.143,134.291v65.829l-44.931-34.482L73.143,134.291z M20.898,187.058
				l146.286,110.759L20.898,396.56V187.058z M45.976,404.919l138.971-93.518l37.094,28.212c2.1,1.623,4.661,2.538,7.314,2.612
				c2.09,0,3.135-1.045,5.224-2.612l38.661-29.78l140.539,95.086H45.976z M433.633,392.903l-143.151-96.131l143.151-109.714V392.903
				z"
            />
            <path
              d="M146.286,117.572h47.02c5.771,0,10.449-4.678,10.449-10.449s-4.678-10.449-10.449-10.449h-47.02
				c-5.771,0-10.449,4.678-10.449,10.449S140.515,117.572,146.286,117.572z"
            />
            <path
              d="M146.286,164.593h167.184c5.771,0,10.449-4.678,10.449-10.449s-4.678-10.449-10.449-10.449H146.286
				c-5.771,0-10.449,4.678-10.449,10.449S140.515,164.593,146.286,164.593z"
            />
            <path
              d="M323.918,201.164c0-5.771-4.678-10.449-10.449-10.449H146.286c-5.771,0-10.449,4.678-10.449,10.449
				s4.678,10.449,10.449,10.449h167.184C319.24,211.613,323.918,206.935,323.918,201.164z"
            />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  </div>
)
