import React from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import Layout from "../components/Layout";
import SendMessage from "../components/SendMessage";
import IconMessage from "../components/Icons/IconMessage";
import IconReceive from "../components/Icons/IconReceive";
import IconSecure from "../components/Icons/IconSecure";

const Index = props => {
  return (
    <>
      <Layout>
        <div>
          <h2>
            Send encrypted messages with a link that automatically
            self-destructs after being read or on expiration.
            <br />
            OTM allows you to share a note with confidentiality - Built
            by vt.team
          </h2>
          <SendMessage />
          <br />
          <br />
          <h2 className="titlePageContent">How it works</h2>
          <div className="howItWorks">
            <div className="hiw">
              <div className="hiwTitle">1. Type your message</div>
              <div className="hiwDescription">
                Create your private message on the form above and select the
                expiration time up to 30 days
              </div>
            </div>
            <div className="hiw">
              <div className="hiwTitle">2. Generate your link</div>
              <div className="hiwDescription">
                When you are ready to share your One Time Message, click the
                Create button
              </div>
            </div>
            <div className="hiw">
              <div className="hiwTitle">3. Share your link</div>
              <div className="hiwDescription">
                A link will appear on the page, you can now share it only once!
              </div>
            </div>
          </div>
          <h3>How do we make sure your messages are private?</h3>
          <div className="howMessagePrivate">
            <div className="hmp">
              <div className="hmpLogo">
                <IconMessage />
              </div>
              <p>
                When we receive your message, we encrypt it and we generate a
                unique URL encrypted as well before storing it to our database.
              </p>
            </div>
            <div className="hmp">
              <div className="hmpLogo">
                <IconReceive />
              </div>
              <p>
                When you click on a link, we encrypt the URL and check if the
                encrypted URL exists in our database.
              </p>
            </div>
            <div className="hmp">
              <div className="hmpLogo">
                <IconSecure />
              </div>
              <p>
                It means that we have no idea of the data stored and the URL
                created. You can be assured that your messages are confidential.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Index
