import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import style from "./style.module.css";
import Loading from "../Icons/Loading";

class SendMessage extends React.Component<any> {
  state = {
    day: 7,
    hour: 0,
    minute: 0,
    message: "",
    url: "",
    loading: false,
    copied: false,
    error: "",
  }

  handleUpdate(event) {
    let value = event.target.value
    if (event.target.name === "day" && value >= 29) {
      value = 29
    }
    if (event.target.name === "hour" && value >= 23) {
      value = 23
    }
    if (event.target.name === "minute" && value >= 59) {
      value = 59
    }
    if (value <= 0) {
      value = 0
    }
    this.setState({
      [event.target.name]: value,
    })
  }

  async createMessage() {
    if (!this.state.message) {
      this.setState({
        url: "",
        error:
          "Error: Your message cannot be empty, type a message and try again",
      })
      return
    }

    this.setState({ loading: true, error: "", url: "" })

    const dayToMin = this.state.day * 1440
    const hourToMin = this.state.hour * 60
    const durationInMin = dayToMin + hourToMin + this.state.minute * 1

    if (durationInMin <= 0) {
      this.setState({
        loading: false,
        error: "Error: Duration must be at least 1 minute",
      })
      return
    }

    try {
      const ENDPOINT_CREATE = "/api/otm/CreateMessage";
      const createMessageRequest = await fetch(ENDPOINT_CREATE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: this.state.message,
          duration: durationInMin
        })
      });
      const createMessageResponse = await createMessageRequest.json();

      if (!createMessageRequest.ok) {
        this.setState({
          loading: false,
          error: createMessageResponse.error,
        });
      }

      this.setState({
        loading: false,
        url: createMessageResponse.shortURL,
      });
    } catch (error) {
      console.error(error);

      this.setState({
        loading: false,
        error: 'An error occurred, please try again later.',
      });
    }
  }

  render() {
    let siteHost = '';
    let siteProtocol = '';

    if (typeof window !== 'undefined') {
      siteHost = window.location.host;
      siteProtocol = window.location.protocol;
    }

    return (
      <div className={style.messageBox}>
        <h3>New Private Message</h3>
        <textarea
          onChange={e => {
            this.setState({ message: e.target.value })
          }}
          className="message"
          value={this.state.message}
        ></textarea>

        <div className={style.expiration}>
          <div className={style.left}>
            <div className={style.expireInTitle}>Expire in</div>
            <div className={style.selector}>
              <input
                onChange={e => {
                  this.handleUpdate(e)
                }}
                name="day"
                type="number"
                max="29"
                min="0"
                value={this.state.day}
              />{" "}
              Days
            </div>
            <div className={style.selector}>
              <input
                onChange={e => {
                  this.handleUpdate(e)
                }}
                type="number"
                name="hour"
                max="23"
                min="0"
                value={this.state.hour}
              />{" "}
              Hours
            </div>
            <div className={style.selector}>
              <input
                onChange={e => {
                  this.handleUpdate(e)
                }}
                type="number"
                name="minute"
                max="59"
                min="0"
                value={this.state.minute}
              />{" "}
              Minutes
            </div>
          </div>
          <div className={style.right}>
            {this.state.loading ? (
              <div className={style.createBtn}>
                <Loading />
              </div>
            ) : (
              <button
                onClick={() => this.createMessage()}
                className={style.createBtn}
              >
                Create
              </button>
            )}
          </div>
        </div>
        {this.state.error ? (
          <div className={`${style.errorMessage} ${style.marginTop10}`}>
            {this.state.error}
          </div>
        ) : null}
        {this.state.url ? (
          <div className={style.url}>
            Share your One Time URL
            <div className={style.displayURL}>
              <input
                type="text"
                defaultValue={`${siteProtocol}//${siteHost}/r/${this.state.url}`}
              />
              <CopyToClipboard
                className={style.createBtn}
                text={`${siteProtocol}//${siteHost}/r/${this.state.url}`}
                onCopy={() => this.setState({ copied: true })}
              >
                <button>
                  {this.state.copied ? "Copied!" : "Copy to clipboard"}
                </button>
              </CopyToClipboard>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

export default SendMessage
